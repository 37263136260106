import { CalendarDate } from '@internationalized/date';

import { EmissionModeEnum } from '../../../services/graphql/generated';
import type { EmissionFreightModeEnum } from '../types';
import { EmissionFreightVehicleCodeEnum } from '../types';

export const CLOSED_WIDTH = 40;
export const OPEN_WIDTH = 408;
export const CARGO_ACTIVE_KEY = 'cargo';
export const SUPPORTED_WAYPOINT_MODES = [EmissionModeEnum.Air, EmissionModeEnum.Flight, EmissionModeEnum.Sea];
export const PUBLIC_USER_ID_HEADER = 'x-pledge-client-id';
export const PUBLIC_EMBED_KEY_HEADER = 'x-pledge-embed-key';
export const PUBLIC_EMBED_FRAME_ORIGIN_HEADER = 'x-pledge-frame-origin';
export const SUPPORTED_FREIGHT_INPUT_MODES = [
  EmissionModeEnum.Road,
  EmissionModeEnum.Rail,
  EmissionModeEnum.Air,
  EmissionModeEnum.InlandWaterway,
  EmissionModeEnum.Sea,
] as const;
export const GLEC_V3_FRAMEWORK_INCEPTION_DATE = new CalendarDate(2023, 9, 1);
export const PLEDGE_SIGNUP_URL = new URL('https://app.pledge.io/auth/sign-up');

export const EMISSION_FREIGHT_VEHICLE_CODES: Record<EmissionFreightModeEnum, EmissionFreightVehicleCodeEnum[]> = {
  [EmissionModeEnum.Air]: [
    EmissionFreightVehicleCodeEnum.P23A,
    EmissionFreightVehicleCodeEnum.P23Ab,
    EmissionFreightVehicleCodeEnum.P23Af,
    // legacy
    EmissionFreightVehicleCodeEnum.A,
  ],
  [EmissionModeEnum.InlandWaterway]: [
    EmissionFreightVehicleCodeEnum.P23Bmv,
    EmissionFreightVehicleCodeEnum.P23Bmv1,
    EmissionFreightVehicleCodeEnum.P23Bmv2,
    EmissionFreightVehicleCodeEnum.P23Bmv3,
    EmissionFreightVehicleCodeEnum.P23Bmv4,
    EmissionFreightVehicleCodeEnum.P23Bcc,
    EmissionFreightVehicleCodeEnum.P23Bpc,
    EmissionFreightVehicleCodeEnum.P23Bpc1,
    EmissionFreightVehicleCodeEnum.P23Bpc2,
    EmissionFreightVehicleCodeEnum.P23Bpc3,
    EmissionFreightVehicleCodeEnum.P23Btv,
    EmissionFreightVehicleCodeEnum.P23Btv1,
    EmissionFreightVehicleCodeEnum.P23Btv2,
    EmissionFreightVehicleCodeEnum.P23Bcv,
    EmissionFreightVehicleCodeEnum.P23Bcv1,
    EmissionFreightVehicleCodeEnum.P23Bcv2,
    EmissionFreightVehicleCodeEnum.P23Bcvcc,
    // legacy
    EmissionFreightVehicleCodeEnum.Bmv,
    EmissionFreightVehicleCodeEnum.Bmv1,
    EmissionFreightVehicleCodeEnum.Bmv2,
    EmissionFreightVehicleCodeEnum.Bmv3,
    EmissionFreightVehicleCodeEnum.Bcc,
    EmissionFreightVehicleCodeEnum.Btv,
    EmissionFreightVehicleCodeEnum.Btv1,
    EmissionFreightVehicleCodeEnum.Btv2,
    EmissionFreightVehicleCodeEnum.Bcv,
    EmissionFreightVehicleCodeEnum.Bcv1,
    EmissionFreightVehicleCodeEnum.Bcv2,
    EmissionFreightVehicleCodeEnum.Bcvcc,
    EmissionFreightVehicleCodeEnum.Bpc,
    EmissionFreightVehicleCodeEnum.Bpc1,
    EmissionFreightVehicleCodeEnum.Bpc2,
    EmissionFreightVehicleCodeEnum.Bpc3,
  ],
  [EmissionModeEnum.Sea]: [
    EmissionFreightVehicleCodeEnum.P23Sbc,
    EmissionFreightVehicleCodeEnum.P23Sbc1,
    EmissionFreightVehicleCodeEnum.P23Sbc2,
    EmissionFreightVehicleCodeEnum.P23Sbc3,
    EmissionFreightVehicleCodeEnum.P23Sbc4,
    EmissionFreightVehicleCodeEnum.P23Sbc5,
    EmissionFreightVehicleCodeEnum.P23Sbc6,
    EmissionFreightVehicleCodeEnum.P23Sgc,
    EmissionFreightVehicleCodeEnum.P23Sgc1,
    EmissionFreightVehicleCodeEnum.P23Sgc2,
    EmissionFreightVehicleCodeEnum.P23Sgc3,
    EmissionFreightVehicleCodeEnum.P23Sgc4,
    EmissionFreightVehicleCodeEnum.P23Srb,
    EmissionFreightVehicleCodeEnum.P23Srb1,
    EmissionFreightVehicleCodeEnum.P23Srb2,
    EmissionFreightVehicleCodeEnum.P23Srb3,
    EmissionFreightVehicleCodeEnum.P23Srb4,
    EmissionFreightVehicleCodeEnum.P23Srr,
    EmissionFreightVehicleCodeEnum.P23Srr1,
    EmissionFreightVehicleCodeEnum.P23Srr2,
    EmissionFreightVehicleCodeEnum.P23Srr3,
    EmissionFreightVehicleCodeEnum.P23Srr4,
    EmissionFreightVehicleCodeEnum.P23Srp,
    EmissionFreightVehicleCodeEnum.P23Srp1,
    EmissionFreightVehicleCodeEnum.P23Srp2,
    EmissionFreightVehicleCodeEnum.P23Srp3,
    EmissionFreightVehicleCodeEnum.P23Srp4,
    EmissionFreightVehicleCodeEnum.P23Srp5,
    EmissionFreightVehicleCodeEnum.P23Sv,
    EmissionFreightVehicleCodeEnum.P23Sv1,
    EmissionFreightVehicleCodeEnum.P23Sv2,
    EmissionFreightVehicleCodeEnum.P23Sv3,
    EmissionFreightVehicleCodeEnum.P23Scs,
    // legacy
    EmissionFreightVehicleCodeEnum.Sbc,
    EmissionFreightVehicleCodeEnum.Sbc1,
    EmissionFreightVehicleCodeEnum.Sbc2,
    EmissionFreightVehicleCodeEnum.Sbc3,
    EmissionFreightVehicleCodeEnum.Sgc,
    EmissionFreightVehicleCodeEnum.Sgc1,
    EmissionFreightVehicleCodeEnum.Sgc2,
    EmissionFreightVehicleCodeEnum.Scs,
    EmissionFreightVehicleCodeEnum.Srr,
    EmissionFreightVehicleCodeEnum.Srp,
  ],
  [EmissionModeEnum.Rail]: [
    EmissionFreightVehicleCodeEnum.P23T,
    // legacy
    EmissionFreightVehicleCodeEnum.T,
  ],
  [EmissionModeEnum.Road]: [
    EmissionFreightVehicleCodeEnum.P23H,
    EmissionFreightVehicleCodeEnum.P23H1,
    EmissionFreightVehicleCodeEnum.P23H2,
    EmissionFreightVehicleCodeEnum.P23H3,
    EmissionFreightVehicleCodeEnum.P23Hr1,
    EmissionFreightVehicleCodeEnum.P23Hr2,
    EmissionFreightVehicleCodeEnum.P23Hr3,
    EmissionFreightVehicleCodeEnum.P23Hr4,
    EmissionFreightVehicleCodeEnum.P23Hr5,
    EmissionFreightVehicleCodeEnum.P23Ha1,
    EmissionFreightVehicleCodeEnum.P23Ha2,
    EmissionFreightVehicleCodeEnum.P23Ha3,
    EmissionFreightVehicleCodeEnum.P23Ha4,
    EmissionFreightVehicleCodeEnum.P23Ha5,
    EmissionFreightVehicleCodeEnum.P23NaHg,
    EmissionFreightVehicleCodeEnum.P23NaHac,
    EmissionFreightVehicleCodeEnum.P23NaHd,
    EmissionFreightVehicleCodeEnum.P23NaHe,
    EmissionFreightVehicleCodeEnum.P23NaHf,
    EmissionFreightVehicleCodeEnum.P23NaHhb,
    EmissionFreightVehicleCodeEnum.P23NaHltl,
    EmissionFreightVehicleCodeEnum.P23NaHmi,
    EmissionFreightVehicleCodeEnum.P23NaHmo,
    EmissionFreightVehicleCodeEnum.P23NaHp,
    EmissionFreightVehicleCodeEnum.P23NaHr,
    EmissionFreightVehicleCodeEnum.P23NaHs,
    EmissionFreightVehicleCodeEnum.P23NaHt,
    EmissionFreightVehicleCodeEnum.P23NaHtl,
    EmissionFreightVehicleCodeEnum.P23V,
    // legacy
    EmissionFreightVehicleCodeEnum.H,
    EmissionFreightVehicleCodeEnum.H1,
    EmissionFreightVehicleCodeEnum.H2,
    EmissionFreightVehicleCodeEnum.H3,
    EmissionFreightVehicleCodeEnum.Hr1,
    EmissionFreightVehicleCodeEnum.Hr2,
    EmissionFreightVehicleCodeEnum.Hr3,
    EmissionFreightVehicleCodeEnum.Hr4,
    EmissionFreightVehicleCodeEnum.Hr5,
    EmissionFreightVehicleCodeEnum.Ha1,
    EmissionFreightVehicleCodeEnum.Ha2,
    EmissionFreightVehicleCodeEnum.Ha3,
    EmissionFreightVehicleCodeEnum.Ha4,
    EmissionFreightVehicleCodeEnum.Ha5,
    EmissionFreightVehicleCodeEnum.NaHg,
    EmissionFreightVehicleCodeEnum.NaHac,
    EmissionFreightVehicleCodeEnum.NaHd,
    EmissionFreightVehicleCodeEnum.NaHe,
    EmissionFreightVehicleCodeEnum.NaHf,
    EmissionFreightVehicleCodeEnum.NaHhb,
    EmissionFreightVehicleCodeEnum.NaHltl,
    EmissionFreightVehicleCodeEnum.NaHmi,
    EmissionFreightVehicleCodeEnum.NaHmo,
    EmissionFreightVehicleCodeEnum.NaHp,
    EmissionFreightVehicleCodeEnum.NaHs,
    EmissionFreightVehicleCodeEnum.NaHt,
    EmissionFreightVehicleCodeEnum.NaHtl,
    EmissionFreightVehicleCodeEnum.V,
  ],
};

export const NORTH_AMERICAN_VEHICLE_CODES = [
  EmissionFreightVehicleCodeEnum.NaHac,
  EmissionFreightVehicleCodeEnum.NaHd,
  EmissionFreightVehicleCodeEnum.NaHe,
  EmissionFreightVehicleCodeEnum.NaHf,
  EmissionFreightVehicleCodeEnum.NaHg,
  EmissionFreightVehicleCodeEnum.NaHhb,
  EmissionFreightVehicleCodeEnum.NaHltl,
  EmissionFreightVehicleCodeEnum.NaHmi,
  EmissionFreightVehicleCodeEnum.NaHmo,
  EmissionFreightVehicleCodeEnum.NaHp,
  EmissionFreightVehicleCodeEnum.NaHs,
  EmissionFreightVehicleCodeEnum.NaHt,
  EmissionFreightVehicleCodeEnum.NaHtl,
  EmissionFreightVehicleCodeEnum.P23NaHg,
  EmissionFreightVehicleCodeEnum.P23NaHac,
  EmissionFreightVehicleCodeEnum.P23NaHd,
  EmissionFreightVehicleCodeEnum.P23NaHe,
  EmissionFreightVehicleCodeEnum.P23NaHf,
  EmissionFreightVehicleCodeEnum.P23NaHhb,
  EmissionFreightVehicleCodeEnum.P23NaHltl,
  EmissionFreightVehicleCodeEnum.P23NaHmi,
  EmissionFreightVehicleCodeEnum.P23NaHmo,
  EmissionFreightVehicleCodeEnum.P23NaHp,
  EmissionFreightVehicleCodeEnum.P23NaHr,
  EmissionFreightVehicleCodeEnum.P23NaHs,
  EmissionFreightVehicleCodeEnum.P23NaHt,
  EmissionFreightVehicleCodeEnum.P23NaHtl,
];

export const OUTSIDE_NORTH_AMERICA_VEHICLE_CODES = [
  EmissionFreightVehicleCodeEnum.H,
  EmissionFreightVehicleCodeEnum.H1,
  EmissionFreightVehicleCodeEnum.H2,
  EmissionFreightVehicleCodeEnum.H3,
  EmissionFreightVehicleCodeEnum.Hr1,
  EmissionFreightVehicleCodeEnum.Hr2,
  EmissionFreightVehicleCodeEnum.Hr3,
  EmissionFreightVehicleCodeEnum.Hr4,
  EmissionFreightVehicleCodeEnum.Hr5,
  EmissionFreightVehicleCodeEnum.Ha1,
  EmissionFreightVehicleCodeEnum.Ha2,
  EmissionFreightVehicleCodeEnum.Ha3,
  EmissionFreightVehicleCodeEnum.Ha4,
  EmissionFreightVehicleCodeEnum.Ha5,
  EmissionFreightVehicleCodeEnum.P23H,
  EmissionFreightVehicleCodeEnum.P23H1,
  EmissionFreightVehicleCodeEnum.P23H2,
  EmissionFreightVehicleCodeEnum.P23H3,
  EmissionFreightVehicleCodeEnum.P23Hr1,
  EmissionFreightVehicleCodeEnum.P23Hr2,
  EmissionFreightVehicleCodeEnum.P23Hr3,
  EmissionFreightVehicleCodeEnum.P23Hr4,
  EmissionFreightVehicleCodeEnum.P23Hr5,
  EmissionFreightVehicleCodeEnum.P23Ha1,
  EmissionFreightVehicleCodeEnum.P23Ha2,
  EmissionFreightVehicleCodeEnum.P23Ha3,
  EmissionFreightVehicleCodeEnum.P23Ha4,
  EmissionFreightVehicleCodeEnum.P23Ha5,
];
